<template>
	<div class="statistics">
		<div class="top-box">
			<sm-card class="box-card-l" noHeader>
				<div slot="body">
					<div class="box-card-title">设备当前 健康状态</div>
					<div class="box-card-l-body" style="margin: 18px 30px;">
						<div class="body-item">
							<div class="body-item-l gBorder flex" style="align-items: center;justify-content: center;">
								<img style="width: 44px; height: 39px" src="@/assets/osImg/icon/bj0.png" />
							</div>
							<div class="body-item-r">
								<div class="body-item-r-title">正常</div>
								<div style="text-align:center">
									<span class="body-item-r-num g">{{ status.alarm0_num }}</span>
									<span class="body-item-r-sp">台</span>
								</div>
							</div>
						</div>
						<div class="body-item">
							<div class="body-item-l bBorder flex" style="align-items: center;justify-content: center;">
								<img style="width: 44px; height: 39px" src="@/assets/osImg/icon/bj1.png" />
							</div>
							<div class="body-item-r" @click="openView('一级报警')">
								<div class="body-item-r-title">一级报警</div>
								<div style="text-align:center">
									<span class="body-item-r-num b">{{ status.alarm1_num }}</span>
									<span class="body-item-r-sp">台</span>
								</div>
							</div>
						</div>
						<div class="body-item">
							<div class="body-item-l fBorder flex" style="align-items: center;justify-content: center;">
								<img style="width: 44px; height: 39px" src="@/assets/osImg/icon/bj2.png" />
							</div>
							<div class="body-item-r" @click="openView('二级报警')">
								<div class="body-item-r-title">二级报警</div>
								<div style="text-align:center">
									<span class="body-item-r-num f">{{ status.alarm2_num }}</span>
									<span class="body-item-r-sp">台</span>
								</div>
							</div>
						</div>
						<div class="body-item">
							<div class="body-item-l rBorder flex" style="align-items: center;justify-content: center;">
								<img style="width: 44px; height: 39px" src="@/assets/osImg/icon/bj3.png" />
							</div>
							<div class="body-item-r" @click="openView('三级报警')">
								<div class="body-item-r-title">三级报警</div>
								<div style="text-align:center">
									<span class="body-item-r-num r">{{ status.alarm3_num }}</span>
									<span class="body-item-r-sp">台</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</sm-card>
			<sm-card class="box-card-r" noHeader>
				<div slot="body">
					<div class="box-card-title">报告总览</div>
					<div class="box-card-r-body">
						<div class="box-card-r-body-item" @click="openView2(1)">
							<div class="flex" style="	align-items: center;">
								<img style="width: 26px; height: 26px" src="@/assets/osImg/icon/icon44.png" />
								<div style="padding-left:10px">月度体检报告</div>
							</div>
							<div class="color-s">{{ rep.month_health_report }}份</div>
						</div>
						<div class="box-card-r-body-item" @click="openView2(2)">
							<div class="flex" style="	align-items: center;">
								<img style="width: 26px; height: 26px" src="@/assets/osImg/icon/icon33.png" />
								<div style="padding-left:10px">设备状态分析报告</div>
							</div>
							<div class="color-s">{{ rep.eqp_stat_analysis_report }}份</div>
						</div>
					</div>
				</div>
			</sm-card>
		</div>
		<sm-card noHeader>
			<div slot="body">
				<el-form class="flex" inline ref="form" :model="searchParm">
					<el-row class="flex-1">
						<el-col :span="20">
							<el-row>
								<el-form-item label="报警日期">
									<el-col :span="10">
										<el-date-picker
											v-model="searchParm.startTime"
											value-format="yyyy-MM-dd"
											type="date"
											@change="qushi()"
											:placeholder="start ? start : '请选择开始日期'"
											:picker-options="pickerOptionsStart"
										></el-date-picker>
									</el-col>
									<el-col :span="2" style="text-align: center;"><span>-</span></el-col>
									<el-col :span="10">
										<el-date-picker
											v-model="searchParm.endTime"
											value-format="yyyy-MM-dd"
											type="date"
											:placeholder="end ? end : '请选择结束日期'"
											 :picker-options="pickerOptionsEnd"
											@change="qushi()"
										></el-date-picker>
									</el-col>
								</el-form-item>
								<span style="line-height:40px;font-size: 16px;color: #999999;">(最大支持12个月区间)</span>
							</el-row>
						</el-col>
					</el-row>
				</el-form>
				<div class="box-card-l-body">
					<div style="width: 50%;height: 600px;" id="main1"></div>
					<div style="width: 50%;height: 600px;" id="main2"></div>
				</div>
			</div>
		</sm-card>
		<div class="bottoms">©2021 圣名科技(广州)有限责任公司 版权所有</div>
	</div>
</template>

<script>
let myChart1, myChart2, option1, option;
import smCard from '@c/smCard.vue';
export default {
	components: {
		smCard
	},
	data() {
		return {
			searchParm: {},
			searchParm2: {},
			rep: {},
			status: {},
			start: null,
			end: null,
			lock: false,
			pickerOptionsStart: {
				disabledDate: time => {
					let endDateVal = this.searchParm.endTime;
					if (endDateVal) {
						return time.getTime() > new Date(endDateVal).getTime();
					}
				}
			},
			pickerOptionsEnd: {
				disabledDate: time => {
					let beginDateVal = this.searchParm.startTime;
					if (beginDateVal) {
						return time.getTime() < new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000;
					}
				}
			}
		};
	},
	mounted() {
		this.init();
		// 在通过mounted调用即可
		this.echartsInit();
		let _this = this;
		myChart1.on('click', function(params) {
			if (!_this.lock) {
				_this.lock = true;
				_this.searchParm2.date = option1.xAxis.data[params.dataIndex];
				_this.fenbu();
			}
		});
		myChart1.getZr().on('click', params => {
			if (_this.lock) {
				_this.lock = false;
				_this.searchParm2.date = '';
				_this.fenbu();
			}
		});
	},
	methods: {
		init() {
			this.$get('/backend-api/alarm/unit/report/overview').then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return;
				}
				this.rep = data.data;
			});
			this.$get('/backend-api/alarm/unit/current/alarm-list').then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return;
				}
				this.status = data.data;
			});
		},
		//初始化echarts
		echartsInit() {
			myChart2 = this.$echarts.init(document.getElementById('main2'));
			myChart1 = this.$echarts.init(document.getElementById('main1'));
			option = {
				title: {
					text: '报警设备分布',
					bottom: 0,
					left: '48%'
				},
				color: ['#52C41A', '#FFDC34', '#E26617', '#D00606'],
				legend: {
					data: ['正常', '一级报警', '二级报警', '三级报警']
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
					confine: true // 加入这一句话
				},
				toolbox: {
					show: true,
					top: 0,
					right: 20,
					zlevel: 899,
					feature: {
						saveAsImage: {
							title: '下载图表',
							pixelRatio: 2
						}
					}
				},
				xAxis: {
					type: 'category',
					data: [],
					name: '(测点区域)'
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: true
					},
					axisTick: {
						show: true
					},
					name: '(设备数)'
				},

				series: [
					{
						name: '正常',
						type: 'bar',
						data: [],
						label: {
							show: true,
							position: 'top',
							formatter: function(params) {
								var html = '';
								if (params.data != 0) {
									html = params.data;
								}
								return html;
							}
						}
					},
					{
						name: '一级报警',
						type: 'bar',
						data: [],
						label: {
							show: true,
							position: 'top',
							formatter: function(params) {
								var html = '';
								if (params.data != 0) {
									html = params.data;
								}
								return html;
							}
						}
					},
					{
						name: '二级报警',
						type: 'bar',
						data: [],
						label: {
							show: true,
							position: 'top',
							formatter: function(params) {
								var html = '';
								if (params.data != 0) {
									html = params.data;
								}
								return html;
							}
						}
					},
					{
						name: '三级报警',
						type: 'bar',
						data: [],
						label: {
							show: true,
							position: 'top',
							formatter: function(params) {
								var html = '';
								if (params.data != 0) {
									html = params.data;
								}
								return html;
							}
						}
					}
				]
			};
			option1 = {
				title: {
					text: '报警趋势图',
					bottom: 0,
					left: '48%',
				},
				grid: [
					{
						bottom: '10%',
						containLabel: true
					}
				],
				tooltip: {
					trigger: 'axis',
					confine: true, // 加入这一句话
					axisPointer: {
						type: 'shadow'
					},
					formatter: function(params) {
						let returnData = '';
						let indexColor = params[0].color;
						let names = params[0].name;
						returnData += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:' + indexColor + '"></span>';
						returnData += `${names}报警设备数：${params[0].value}`;
						return returnData;
					}
				},
				toolbox: {
					show: true,
					top: 0,
					right: 20,
					zlevel: 899,
					feature: {
						saveAsImage: {
							title: '下载图表',
							pixelRatio: 2
						}
					}
				},
				xAxis: {
					type: 'category',
					data: [],
					name: '(日期)'
				},
				yAxis: {
					type: 'value',
					axisLine: {
						show: true
					},
					axisLabel: {
						show: true
					},
					axisTick: {
						show: true
					},
					minInterval: 1,
					name: '(设备数)'
				},
				series: [
					{
						data: [],
						type: 'line',
						label: {
							show: true,
							position: 'top'
						},
						lineStyle:{
							color:'#66bb66' //线条颜色
						},
						itemStyle:{
							color:'#ff6600' 
						}
					}
				],
				dataZoom: [
					{
						type: 'slider',
						filterMode: 'empty',
						show: true,
						realtime: true,
						start: 0,
						end: 100,
						bottom: '5%',
						showDetail: false,
						realtime: true
					},
					{
						type: 'inside',
						realtime: true
					}
				]
			};
			this.qushi();
			this.fenbu();
			window.addEventListener('resize', () => {
				if (myChart1) {
					myChart1.resize();
				}
				if (myChart2) {
					myChart2.resize();
				}
			});
		},
		qushi() {
			this.$get('/backend-api/alarm/unit/alarm/trend', this.searchParm).then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return;
				}
				option1.xAxis.data = data.data.xData;
				option1.series[0].data = data.data.yData;
				this.start = data.data.xData[0];
				let endNum = data.data.xData.length - 1;
				this.end = data.data.xData[endNum];

				// this.searchParm.startTime =data.data.xData[0];
				// let endNum = data.data.xData.length-1;
				// this.searchParm.endTime =data.data.xData[endNum];
				// console.log(this.searchParm)
				myChart1.setOption(option1, true);
			});
		},
		fenbu() {
			this.$get('/backend-api/alarm/unit/region', this.searchParm2).then(data => {
				if (data.code != 1000) {
					this.$message.error(data.msg);
					return;
				}
				option.xAxis.data = data.data.xData;
				option.series[0].data = data.data.y0Data;
				option.series[1].data = data.data.y1Data;
				option.series[2].data = data.data.y2Data;
				option.series[3].data = data.data.y3Data;
				myChart2.setOption(option, true);
			});
		},
		openView(e) {
			this.$router.push({ name: 'companyalarmInfo', params: { alarmStatus: e } });
		},
		openView2(e) {
			this.$router.push({ name: 'companyReport', params: { type: e } });
		}
	}
};
</script>

<style scoped>
.statistics {
	padding: 10px;
}
.top-box {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.box-card-l {
	width: 75%;
	height: 200px;
	/* padding: 12px !important; */
}
.box-card-l-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.body-item {
	width: 20%;
	height: 100px;
	border-radius: 2px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
	/* margin-top: 18px; */
}
.body-item-l {
	width: 40%;
	height: 100px;
	border-radius: 2px;
}
.body-item-r {
	width: 60%;
	border-radius: 2px;
	cursor: pointer;
}
.body-item-r-title {
	text-align: center;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #333333;
	line-height: 25px;
}
.body-item-r-num {
	font-size: 28px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	line-height: 40px;
}
.body-item-r-sp {
	font-size: 18px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;
	line-height: 25px;
}
.box-card-r {
	min-width: 400px;
	width: 25%;
	height: 200px;
	margin-left: 12px !important;
	/* padding: 12px !important; */
}
.box-card-title {
	font-size: 16px;
	font-weight: 500;
	color: #262626;
	line-height: 22px;
}
.box-card-r-body {
	padding: 12px;
}
.box-card-r-body-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	font-weight: bold;
	color: #666666;
	line-height: 20px;
	height: 50px;
	cursor: pointer;
}
.bottoms {
	position: fixed;
	width: 100%;
	text-align: center;
	left: 0;
	bottom: 20px;
}

.color-s {
	color: #4a99f6;
}
.g {
	color: #52c41a;
}

.b {
	color: #ffdc34;
}

.f {
	color: #e26617;
}

.r {
	color: #d00606;
}
.gBorder {
	background: rgba(82, 196, 26, 0.3);
}

.bBorder {
	background: rgba(255, 220, 52, 0.3);
}

.fBorder {
	background: rgba(226, 102, 23, 0.3);
}

.rBorder {
	background: rgba(208, 6, 6, 0.3);
}

::v-deep .el-form-item__label {
	font-size: 16px !important;
}
</style>
